import styled from "styled-components";

export const ProfileContent = styled.div`
  margin: 0 auto 100px auto;
  button {
    @media screen and (max-width: 400px) {
      width: 90%;
    }
    margin-top: 15px;
    padding: 10px 38px;
    font-size: 13px;
    position: relative;
    background-color: transparent;
    border: none;

    &::before {
      cursor: pointer;
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 20px;
      padding: 2px;
      background: linear-gradient(
        to right,
        #feb1a7 0%,
        #ff8975 11%,
        #fe8458 22%,
        #feba61 33%,
        #fed479 44%,
        #d7eca7 55%,
        #77e6bf 67%,
        #13aeba 78%,
        #016b99 89%,
        #052e5d 100%
      );
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
    }
  }
`;
export const ProfileWrapper = styled.div`
  display: flex;
  div {
    display: flex;
    flex-direction: column;
    font-size: 13px;
  }
  & > div:first-child {
    color: #d4d4d4 !important;
    align-items: flex-end;
  }
  & > div:nth-child(2) {
    align-items: flex-start;
    margin-left: 17px;
  }
`;
