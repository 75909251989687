export const term_data = [
  {
    title: "1. How we collect your data",
    content: (
      <div>
        <p>
          We primarily collect personal information directly from you, such as:
        </p>
        <p>
          However, when you register to use a Galambo site and generate images
          as a Galambo subscriber, we may collect the following Personal
          Information from you:
        </p>
        <ul>
          <li>
            <strong>Contact Information</strong>: Your name, phone number,
            address, and email address.
          </li>
          <li>
            <strong>Demographic Information</strong>: Your job title, job
            description, company name, and company type.
          </li>
          <li>
            <strong>Profile Data</strong>: Your username and password, details
            about your activity on the Site, including search terms and the
            content you generate.
          </li>
        </ul>
        <p>
          If you are logged in to Galambo for image generation, you may be asked
          to provide the following information:
        </p>
        <p>
          Contact Information: Your name, phone number, primary address, and
          email address.
        </p>
        <p>
          Depending on the activity, some information we ask for is mandatory,
          and some is voluntary. If you do not provide the mandatory data for a
          particular activity, you will not be able to engage in that activity.
        </p>
        <p>
          Like most websites, when you use the Site, Galambo or third-party
          subprocessors contracted by Galambo may also collect certain technical
          and routing information about your computer to facilitate your use of
          the Site and its services. For example, we may log environmental
          variables, such as browser type, operating system, CPU speed, and the
          Internet Protocol (IP) address of your computer. We use these
          environmental variables to facilitate and track your use of the Site
          and its services. Galambo also uses such environmental variables to
          measure traffic patterns on the Site. To better understand the needs
          of our visitors to the Site, we may occasionally match such
          information with your Personal Information.
        </p>
        <p>
          To reduce errors in our database, authenticate our users, and prevent
          abuse of our system, we may on occasion supplement the Personal
          Information you submit to us with information from third-party
          sources. For example, we may supplement your contact information with
          address information provided by the Google Maps API or the U.S. Postal
          Service to qualify your information and prevent errors in our
          database. Where we use the Google Maps API, the Google Privacy Policy
          is incorporated by reference solely to the personal information
          processed by Google. We may supplement the information that we collect
          directly from you with information stored in third-party databases,
          such as demographic information or company information to make it more
          likely that any marketing communications we send will be relevant and
          of interest to you. You may find a list of any third-party
          subprocessors that we use to process information [here](insert link).
        </p>
        <p>
          When you download and use our Services, we automatically collect
          information on the type of device you use and the operating system
          version. We do not ask for, access, or track any location-based
          information from your mobile device at any time while downloading or
          using our Services.
        </p>
      </div>
    ),
  },
  {
    title: "2. Updating Your Personal Information and Privacy Preferences",
    content: (
      <div>
        <p>
          Upon request, Galambo will provide you with information about and
          access to your Personal Information that we process. To request this
          information please contact us through the Privacy Request form.
        </p>
        <p>
          You have the right to access and correct or delete your Personal
          Information and privacy preferences at any time. This may be
          accomplished by clicking on the link, "My Account", where you can view
          and make changes to most of your Personal Information immediately. If
          you wish to delete your account or request that we no longer use your
          information to provide you services visit the "My Account Preferences"
          link on the site and follow the instructions under the section "Delete
          My Account". If you are a contributor, you can change your profile
          here. For security purposes, certain Personal Information can only be
          changed by contacting support. We will respond to your request
          promptly within a reasonable timeframe.
        </p>
      </div>
    ),
  },
  {
    title: "3. How Your Personal Information Is Used",
    content: (
      <div>
        <p>
          Galambo collects your information to provide services to you, comply
          with our legal obligations, and improve our products and services. We
          do not sell, rent, or share your personally identifiable information
          to or with third parties in any way other than as disclosed in this
          Privacy Policy. Galambo may use this information to:
        </p>
        <ul>
          <li>
            Authenticate and provide access to the Galambo websites and
            services.
          </li>
          <li>
            Register your rights, if any, to technical support or other benefits
            that may be made available to registered users.
          </li>
          <li>Administer your account.</li>
          <li>
            Investigate, prevent, or take action regarding illegal activities
            and/or violations of our Terms of Service.
          </li>
        </ul>
        <p>
          We store information that we collect through cookies, log files,
          transparent images, and/or third-party sources to create a summary of
          your preferences.
        </p>
        <p>
          In certain situations, Galambo may be required to comply with legal
          obligations to disclose personal data in response to lawful requests
          by public authorities, including to meet national security or law
          enforcement requirements.
        </p>
        <p>
          Where required by law (e.g., to comply with a subpoena, warrant, court
          order, or legal process served on Galambo), and when we believe that
          disclosure is necessary to protect our rights, avoid litigation,
          protect your safety or the safety of others, investigate fraud, and/or
          respond to a government request. We may also disclose information
          about you to comply with our legal obligations if we determine that
          such disclosure should be made for reasons of national security, law
          enforcement, or other issues of public importance.
        </p>
      </div>
    ),
  },
  {
    title: "4. Legal Bases for Processing",
    content: (
      <div>
        <p>
          For individuals located in the European Economic Area (EEA) and the
          UK, we rely on the following legal bases for the processing of your
          data:
        </p>
        Where processing is necessary to perform our contract with you:
        <ul>
          <li>
            Providing access to the Galambo websites and services to fulfill the
            terms of your contract;
          </li>
          <li>
            Customizing your experience, including targeting our services,
            search results, and offerings to you;
          </li>
          <li>
            Providing information so that you can make decisions about Galambo
            services;
          </li>
          <li>
            Register your rights, if any, to technical support or other benefits
            that may be made available to registered users;
          </li>
          <li>Administering your account.</li>
          <li>
            Processing is necessary to protect the vital interests of the data
            subject or another natural person:
          </li>
          <li>
            Investigating and protecting against intellectual property
            infringement;
          </li>
          <li>
            Investigating, preventing, or taking action regarding violations of
            our Terms of Service;
          </li>
          <li>Processing in furtherance of Galambo’s legitimate interests:</li>
          <li>
            Providing, improving, and customizing our website and services;
          </li>
          <li>
            Analyzing and understanding how our online platforms are being used;
          </li>

          <li>
            Maintaining the security and integrity of our websites and other
            services;
          </li>
          <li>
            Sending you requested product or service information and keeping you
            informed about special offers and services of Shutterstock and
            selected third parties;
          </li>
          <li>
            Research and product/service development for the improvement of our
            Site, services, and offerings;
          </li>
          <li>
            Processing is necessary for compliance with Galambo’s legal
            obligations, including;
          </li>
          <li>
            Investigating and protecting against intellectual property
            infringement, fraud, spam, harassment, crime and security risks;
          </li>
          <li>
            Compliance with money laundering, bribery, corruption, and
            applicable sanctions laws and regulations; and
          </li>
          <li>
            Meeting financial reporting and other obligations under applicable
            securities laws.
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: "5. Data Retention and Data Portability",
    content: (
      <div>
        <p>
          Galambo does not sell your personal data or personal information to
          any third party. We do use third-party advertising services to manage
          the advertisements that we place on other sites. Our third-party
          partner may use technologies such as cookies and trackers to gather
          information about your activities on this site and other sites to
          provide you with advertising based on your browsing activities and
          interests. If you wish to not have this information used to serve you
          interest-based ads, you may opt out by clicking here (or if located in
          the European Union click here)
        </p>
      </div>
    ),
  },
  {
    title: "6. Your Choices (Opt Out)",
    content: (
      <div>
        <p>
          Galambo does not sell your personal data or personal information to
          any third party. We do use third-party advertising services to manage
          the advertisements that we place on other sites. Our third-party
          partner may use technologies such as cookies and trackers to gather
          information about your activities on this site and other sites to
          provide you with advertising based on your browsing activities and
          interests. If you wish to not have this information used to serve you
          interest-based ads, you may opt out by clicking here (or if located in
          the European Union click here)
        </p>
        <p>
          Galambo will only use your personal information for the purposes
          described in this Privacy Statement and will not use your personal
          information for a purpose that is materially different without your
          express authorization.
        </p>
        <p>
          You may "opt out" of receiving Galambo’s email updates, newsletters,
          and/or partner emails by clicking on the "My Account" link on the Site
          and making the appropriate selections. The choice to opt out of such
          communications is also generally available during the sign-up process.
          Galambo will still contact you when there are changes to the Terms of
          Service or Submitter Terms of Service, as applicable. In addition, we
          will still send you service-related announcements including, but not
          limited to, a registration email, benefit notification emails, and
          emails automatically triggered by actions you took on the Site.
          Generally, you may not opt out of these communications, which are not
          promotional. If you wish not to receive them, you have the option of
          deactivating your account
        </p>
        <p>
          You may request the deletion of your Personal Data from our systems
          and/or opt-out of further processing of your Data by visiting the "My
          Account – Preferences" page and following the instructions under the
          section titled "Delete My Data". If you are unable to access your
          account, please contact us.
        </p>
      </div>
    ),
  },
  {
    title: "7. Protection of Your Personal Information",
    content: (
      <div>
        <p>
          The Personal Information that you provide in connection with the use
          of the Site is protected in several ways.
        </p>
        <p>
          Your Personal Information is encrypted whenever it is transmitted to
          Galambo.
        </p>
        <p>
          We strive to protect the Personal Information submitted to us, both
          during transmission and once we receive it. However, no method of
          transmission over the Internet, or method of electronic storage, is
          100% secure. While we take into account the sensitivity of the
          Personal Information we collect, process, and store, and the current
          state of technology to use these measures to protect your Personal
          Information, we cannot guarantee its absolute security.
        </p>
        <p>
          If you have any questions about security on our Websites, please
          contact us through our privacy request form.
        </p>
      </div>
    ),
  },
  {
    title: "8. Cookie and Tracking Technologies",
    content: (
      <div>
        <p>
          The Site may automatically collect information as you browse, such as
          Internet Service Provider, browser type and version, operating system,
          and device type, average time spent on our site(s), pages viewed,
          information accessed, the Internet Protocol (IP) address used to
          connect your computer to the Internet and other relevant statistics.
          Such information is sometimes referred to as web analytics and/or
          clickstream data.
        </p>
        <p>
          We may combine this automatically collected log information with other
          information we collect about you. We do this to improve the services
          we offer you, to improve marketing, and analytics, and to provide site
          functionality.
        </p>
        <p>
          Technologies such as cookies are used by Galambo and our affiliates,
          analytics services, and services to provide site features and
          functionality. These technologies are used in analyzing trends,
          administering the site, tracking users’ movements around the site, and
          gathering demographic information about our user base as a whole. We
          may receive reports based on the use of these technologies by these
          companies on an individual as well as aggregated basis.
        </p>
        <p>
          A cookie is a small, unique text file that a website can send to your
          computer when you visit a site. We may use session cookies, which
          terminate when a user closes his or her browser, and/or persistent
          cookies, which remain on the user's computer until manually deleted.
          Most web browsers can either alert you to the use of cookies or refuse
          to accept cookies entirely. If you do not want us to deploy cookies to
          your browser, you can set your browser to reject cookies or notify you
          when a website tries to put a cookie on your computer. Rejecting
          cookies may affect your ability to use the Site.
        </p>
      </div>
    ),
  },
  {
    title: "9. Changes in Privacy Policy",
    content: (
      <div>
        <p>
          Galambo reserves the right to amend the Privacy Policy from time to
          time at its sole discretion and will provide notice by email or on the
          home page of the Site when we make material changes to this Privacy
          Policy before the change becoming effective. If as a result of such
          changes, you want to alter how Galambo is allowed to use your Personal
          Information, you can do so by following the procedure described in the
          section entitled "Updating Your Personal Information and Privacy
          Preferences."
        </p>
        <p>
          You will be deemed to have been made aware of, and will be subject to,
          any material changes to the Privacy Policy after such notice has been
          posted with the following exception: If at the time you provide
          Personal Information to Galambo you are given the opportunity (as you
          currently are) to limit how such information will be used to
          communicate with you, either by Galambo or by third parties, Galambo
          will not change your preference in this regard without your express
          consent. However, if Galambo is acquired by or merged with another
          entity, Galambo may provide to such entity customer information that
          is related to that part of our business that was sold to or merged
          with the other entity without your consent, but Galambo will provide
          notice of such asset sales, acquisitions, or mergers on this Site.
        </p>
      </div>
    ),
  },
  {
    title: "10. Single Sign-On",
    content:
      "You may be able to log in to our site using sign-in services such as Sign in with Apple, Facebook Connect, Sign in with Google, or an Open ID provider. These services will authenticate your identity and provide you the option to share certain personal information with us such as your name and email address to pre-populate our sign-up form. Services like Facebook Connect give you the option to post information about your activities on this Website to your profile page to share with others within your network.",
  },
  {
    title: "11. Privacy Complaints",
    content: (
      <div>
        <p>
          Individuals located in certain countries, including the European
          Economic Area, have certain statutory rights about their data. Subject
          to any exemptions provided by law, you may have the right to request
          access to Personal Data, seek to update, delete, or correct this
          Information, and request to restrict further processing of your
          Personal Data. To the extent that Galambo's processing of your
          Personal Data is subject to the EU General Data Protection Regulation,
          Galambo relies on its legitimate interests, described above, to
          process your data. Where Galambo processes certain Personal Data for
          Galambo's direct marketing purposes, you have a right to object to
          Galambo's use of your Personal Data for this purpose at any time by
          clicking on the link, "My Account" and opting out from receiving all
          email. If you are a contributor, you can change your profile here.
        </p>
        <p>
          You may request the deletion of your Personal Data from our systems
          and/or opt-out of further processing of your Data by visiting the "My
          Account – Preferences" page and following the instructions under the
          section titled "Delete My Personal Data"
        </p>
        <p>
          If you wish to make any other objection to the processor of your data,
          you may also object to processing by submitting your objection through
          our Privacy Request form.
        </p>
        <p>
          Galambo takes its user's privacy concerns seriously. If you have any
          questions about this Privacy Policy, or if you believe that Galambo
          has not complied with this Privacy Policy concerning your Personal
          Information, you may contact the Data Protection Officer.
        </p>
      </div>
    ),
  },
];
