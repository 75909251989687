import React from "react";
import * as Styled from "./style";
import { useAppContext } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
export default function Profile() {
  const [context, setContext] = useAppContext();
  const navigate = useNavigate();
  const logoutUser = () => {
    setContext({ ...context, auth: null });
    localStorage.clear();
    navigate("/login");
  };
  return (
    <Styled.ProfileContent>
      <Helmet>
        <title>Profile Page | Galambo</title>
        <meta name="description" content="Profile page of galambo" />

        <link rel="canonical" href="http://www.galambo.com/profile" />
      </Helmet>
      <h1 itemProp="headline">Account</h1>
      {context.auth !== null && (
        <Styled.ProfileWrapper>
          <div>
            <span>Full Name:</span>
            <span>Email:</span>
            <span>Password:</span>
          </div>
          <div>
            <span>{context.auth.user.name}</span>
            <span>{context.auth.user.email}</span>
            <span>*******</span>
          </div>
        </Styled.ProfileWrapper>
      )}
      <button onClick={logoutUser}>logout</button>
    </Styled.ProfileContent>
  );
}
