import React from "react";
import * as Styled from "./style";

import { term_data } from "./data";
import { Helmet } from "react-helmet";
const Terms = () => {
  return (
    <Styled.TermContainer>
      <Helmet>
        <title>Terms & Conditions Page | Galambo</title>
        <meta name="description" content="Terms & Conditions page of galambo" />

        <link rel="canonical" href="http://www.galambo.com/term" />
      </Helmet>
      <Styled.TermWrapper>
        <h1 itemProp="headline">Terms & Conditions</h1>
        {term_data.map((item, key) => (
          <Styled.TermProvider key={key}>
            <h3>{item.title}</h3>
            <span>{item.content}</span>
          </Styled.TermProvider>
        ))}
      </Styled.TermWrapper>
    </Styled.TermContainer>
  );
};
export default Terms;
